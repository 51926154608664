import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

const rumApplicationId = process.env.REACT_APP_RUM_APPLICATION_ID;
const rumClientToken = process.env.REACT_APP_RUM_CLIENT_TOKEN;

//
// if (rumApplicationId && rumClientToken) {
//   datadogLogs.init({
//     clientToken: rumClientToken,
//     site: 'datadoghq.com',
//     forwardErrorsToLogs: true,
//     sessionSampleRate: 100,
//   });
//
//   datadogRum.init({
//     applicationId: rumApplicationId,
//     clientToken: rumClientToken,
//     site: 'datadoghq.com',
//     service: 'dispatcher-app',
//     env: process.env.REACT_APP_RUM_ENV,
//     version: process.env.REACT_APP_RUM_VERSION,
//     sessionSampleRate: 100,
//     sessionReplaySampleRate: 100,
//     trackResources: true,
//     trackLongTasks: true,
//     trackUserInteractions: true,
//     defaultPrivacyLevel: 'allow',
//     allowedTracingUrls: [
//       { match: /https:\/\/dataorch.*\.axlehire\.com/, propagatorTypes: ["datadog"]},
//       { match: /https:\/\/dataorch.*\.gojitsu\.com/, propagatorTypes: ["datadog"]}
//     ]
//   });
//   datadogRum.startSessionReplayRecording();
// }

ReactDOM.createRoot(document.getElementById('root')).render(<App />);
serviceWorker.unregister();
